// Reboot

// Make the edges of fonts smoother + apply globally :focus-visible styles

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus-visible {
    outline: var(--#{$prefix}focus-ring-width) solid var(--#{$prefix}focus-ring-color);
  }
}


// Disable :focus-visible for offcanvas component

.offcanvas:focus-visible {
  outline: none;
}


// Sticky footer

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrapper {
  flex: 1;
}
.footer {
  flex-shrink: 0;
}


// Default text color

p,
ul,
ol {
  color: var(--#{$prefix}body-color);
}


// Images
// Responsive images (ensure images don't scale beyond their parents)

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg {
  max-width: 100%;
}


// Responsive iframes

iframe {
  width: 100%;
}


// Links

a {
  text-underline-offset: .25em;
}

.link-body-emphasis {
  --#{$prefix}link-opacity: 1;
}


// Text Selection Color

::selection {
  background: var(--#{$prefix}user-selection-color);
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    ::selection {
      background: var(--#{$prefix}user-selection-color-dark);
    }
  }
}


// Lists

ol,
ul {
  display: flex;
  flex-direction: column;
  gap: $spacer * .5;
  padding-left: $spacer * 1.5;

  ul,
  ol {
    margin-top: $spacer * .5;
  }
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

dt {
  color: $dt-color;
}

dd {
  margin-bottom: $spacer * .75;
}


// Legend

legend {
  color: $legend-color;
}


// Base font icons styles

[class*=" #{$icon-prefix}"],
[class^="#{$icon-prefix}"] {
  display: inline-flex;
}


// Code

pre {
  padding: $pre-padding-y $pre-padding-x;
  margin: 0;
  background-color: $pre-bg;
  @include border-radius($pre-border-radius);
}

kbd {
  @include border-radius($border-radius-xs);
}


// Hero index page

.index-hero-wrapper {
  height: auto;
  min-height: 100vh;
  @include media-breakpoint-up(md) {
    height: 100vh;
    min-height: 930px;
  }
}


// Footer links

.footer .nav-link {
  &:hover {
    text-decoration: underline;
  }
}
