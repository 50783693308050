// Custom scrollbar
// based on https://github.com/Grsmto/simplebar

// Base styles

[data-simplebar] {
  --#{$prefix}scrollbar-width: #{$scrollbar-width};
  --#{$prefix}scrollbar-border-radius: #{$scrollbar-border-radius};
  --#{$prefix}scrollbar-bg: #{$scrollbar-bg};
  --#{$prefix}scrollbar-track-bg: #{$scrollbar-track-bg};


  // Scrollbar
  .simplebar-scrollbar {
    &::before {
      right: 0;
      left: 0;
      background-color: var(--#{$prefix}scrollbar-bg);
      @include border-radius(var(--#{$prefix}scrollbar-border-radius));
    }
    &.simplebar-visible::before { opacity: 1; }
  }


  // Track
  .simplebar-track {
    @include border-radius(var(--#{$prefix}scrollbar-border-radius));

    &.simplebar-vertical {
      width: var(--#{$prefix}scrollbar-width);
      .simplebar-scrollbar::before {
        top: 0;
        bottom: 0;
      }
    }

    &.simplebar-horizontal {
      height: var(--#{$prefix}scrollbar-width);
      .simplebar-scrollbar {
        top: 0;
        bottom: 0;
        height: var(--#{$prefix}scrollbar-width);
        background-color: var(--#{$prefix}scrollbar-bg);
        opacity: 0;
        transition: opacity .3s ease-in-out;
        &.simplebar-visible {
          opacity: 1;
        }
        &::before {
          right: 0;
          left: 0;
        }
      }
    }
  }


  // Autohide option
  &[data-simplebar-auto-hide="false"] {
    .simplebar-track { background-color: var(--#{$prefix}scrollbar-track-bg); }
  }
}


// Dark color mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    [data-simplebar] {
      --#{$prefix}scrollbar-bg: #{$scrollbar-bg-dark};
      --#{$prefix}scrollbar-track-bg: #{$scrollbar-track-bg-dark};
    }
  }
}
