// Tables

// Table without side-cell padding
.table-flat {
  tr {
    th,
    td {
      &:first-child { padding-left: initial; }
      &:last-child {
        padding-right: initial;
        text-align: right;
      }
    }
  }
}
