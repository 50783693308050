// Extend default Bootstrap's .form-control / .form-select classes

.form-control,
.form-select {
  --#{$prefix}form-control-bg: #{$input-bg};
  --#{$prefix}form-control-border-color: #{$input-border-color};
  --#{$prefix}form-control-focus-bg: #{$input-focus-bg};
  --#{$prefix}form-control-focus-border-color: #{$input-focus-border-color};

  background-color: var(--#{$prefix}form-control-bg);
  border-color: var(--#{$prefix}form-control-border-color);

  &:focus {
    background-color: var(--#{$prefix}form-control-focus-bg);
    border-color: var(--#{$prefix}form-control-focus-border-color);
  }

  &:disabled {
    border-style: dashed;
  }
}


// Remove default -webkit search input clear button

.form-control {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}


// Switch

.form-switch {
  min-height: $form-switch-height;
  margin-bottom: $form-switch-margin-bottom;

  .form-check-input {
    height: $form-switch-height;
    margin-top: 0;
    background-color: $form-switch-bg-color;
    border: 0 !important; // stylelint-disable-line declaration-no-important

    &:checked {
      background-color: $form-switch-checked-bg-color !important; // stylelint-disable-line declaration-no-important
    }
  }
}


// Password toggle

.password-toggle {
  position: relative;

  .form-control {
    padding-right: $input-padding-x * 2 + $input-font-size;
  }
}

.password-toggle-button {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 $input-padding-x;
  color: $password-toggle-button-color;
  cursor: pointer;
  transition: color .2s ease-in-out;

  &:hover {
    color: $password-toggle-button-hover-color;
  }
}


// Dark mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .form-control:not([data-bs-theme="light"]) {
      &::placeholder {
        color: $input-placeholder-color-dark;
      }
    }
    .form-control:not([data-bs-theme="light"]),
    .form-select:not([data-bs-theme="light"]) {
      --#{$prefix}form-control-bg: #{$input-bg-dark};
      --#{$prefix}form-control-border-color: #{$input-border-color-dark};
      --#{$prefix}form-control-focus-bg: #{$input-focus-bg-dark};
      --#{$prefix}form-control-focus-border-color: #{$input-focus-border-color-dark};
      color: $input-color-dark;
    }

    .form-select:not([multiple]) option {
      background-color: $dark;
    }

    .form-check-input:not([data-bs-theme="light"]) {
      background-color: $form-check-input-bg-dark;
      border: $form-check-input-border-dark;

      &:checked {
        background-color: $form-check-input-checked-bg-color-dark;
        border-color: $form-check-input-checked-border-color-dark;

        &[type="checkbox"] {
          @if $enable-gradients {
            --#{$prefix}form-check-bg-image: #{escape-svg($form-check-input-checked-bg-image-dark)}, var(--#{$prefix}gradient);
          } @else {
            --#{$prefix}form-check-bg-image: #{escape-svg($form-check-input-checked-bg-image-dark)};
          }
        }

        &[type="radio"] {
          @if $enable-gradients {
            --#{$prefix}form-check-bg-image: #{escape-svg($form-check-radio-checked-bg-image-dark)}, var(--#{$prefix}gradient);
          } @else {
            --#{$prefix}form-check-bg-image: #{escape-svg($form-check-radio-checked-bg-image-dark)};
          }
        }
      }

      &[type="checkbox"]:indeterminate {
        background-color: $form-check-input-indeterminate-bg-color-dark;
        border-color: $form-check-input-indeterminate-border-color-dark;

        @if $enable-gradients {
          --#{$prefix}form-check-bg-image: #{escape-svg($form-check-input-indeterminate-bg-image-dark)}, var(--#{$prefix}gradient);
        } @else {
          --#{$prefix}form-check-bg-image: #{escape-svg($form-check-input-indeterminate-bg-image-dark)};
        }
      }
    }


    .was-validated .form-check-input:invalid,
    .form-check-input.is-invalid {
      border-color: var(--#{$prefix}form-invalid-border-color);
    }

    .form-switch:not([data-bs-theme="light"]) {
      .form-check-input {
        background-color: $form-switch-bg-color-dark;
        border: 0;

        &:checked {
          background-color: $form-switch-checked-bg-color;
        }

        &:not(:checked):not(:focus) {
          --#{$prefix}form-switch-bg: #{escape-svg($form-switch-bg-image)};
        }
      }
    }

    .count-input:not([data-bs-theme="light"]) {
      background-color: $input-bg-dark;
      border-color: $input-border-color-dark;
    }

    .input-group:not([data-bs-theme="light"]) .input-group-text {
      border-color: $input-group-addon-border-color-dark;
    }
  }
}


// Theme mode switcher

.theme-mode-switcher {
  .btn-check:not([checked]) + .li-theme-mode-icon {
    &::before {
      content: "\f165";
    }
  }
  .btn-check:checked + .li-theme-mode-icon {
    &::before {
      content: "\f14e";
    }
  }
}
