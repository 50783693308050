// Card


// Hover state

.card-hover {
  --#{$prefix}card-transition: #{$card-transition};
  transition: var(--#{$prefix}card-transition);

  &:hover {
    @include box-shadow($box-shadow-sm);
  }
  &.shadow-sm:hover {
    @include box-shadow($box-shadow !important); // stylelint-disable-line declaration-no-important
  }
}


// Hover state invert

.card-hover-inverse {
  transition: filter .25s ease-in-out;

  &:hover {
    filter: grayscale(1) invert(1);
    transform: translateZ(0);
  }
}


// Catalog CTA

.catalog-single-cta {
  --#{$prefix}card-spacer-x: #{$container-padding-x * .5};
  --#{$prefix}card-border-radius: 0;
  --#{$prefix}card-border-width: 0;

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed - 1;

  .card-body {
    width: 100%;
    max-width: 95%;
    margin: {
      right: auto;
      left: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    --#{$prefix}card-spacer-x: #{$card-spacer-x};
    --#{$prefix}card-border-radius: #{$card-border-radius};

    position: static;
    top: 112px;
    right: initial;
    bottom: initial;
    left: initial;

    .card-body {
      max-width: initial;
      margin: {
        right: initial;
        left: initial;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    --#{$prefix}card-spacer-y: #{$card-spacer-y * 2};
    --#{$prefix}card-spacer-x: #{$card-spacer-x * 2};
  }
}


// Salary row item hover effect

@include media-breakpoint-up(xl) {
  .salary-item {
    position: relative;
    &:hover {
      .salary-title {
        margin-left: $spacer;
      }
    }
  }
  .salary-title {
    transition: margin .25s ease-in-out;
  }
}
