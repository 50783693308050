// Override Bootstrap's base button class

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    box-shadow: $focus-ring-box-shadow;
  }
}


// Override solid buttons

.btn-secondary {
  --#{$prefix}btn-color: #{$gray-700};
  --#{$prefix}btn-hover-color: #{$gray-900};
  --#{$prefix}btn-active-color: #{$gray-900};
  --#{$prefix}btn-focus-color: #{$gray-900};
  --#{$prefix}btn-bg: #{$gray-200};
  --#{$prefix}btn-hover-bg: #{$gray-300};
  --#{$prefix}btn-active-bg: #{$gray-300};
  --#{$prefix}btn-focus-bg: #{$gray-300};
}


// Override outline buttons

.btn-outline-secondary {
  --#{$prefix}btn-border-color: #{$gray-300};
  --#{$prefix}btn-color: #{$gray-700};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-hover-border-color: #{$gray-600};
  --#{$prefix}btn-hover-bg: transparent;
  --#{$prefix}btn-active-border-color: #{$gray-600};
  --#{$prefix}btn-active-bg: transparent;
}


// Override link button

.btn-link {
  --#{$prefix}btn-link-decoration: #{$btn-link-decoration};
  --#{$prefix}btn-link-hover-decoration: #{$btn-link-hover-decoration};
  --#{$prefix}btn-color: #{$primary};
  --#{$prefix}btn-hover-color: #{$primary};
  --#{$prefix}btn-active-color: #{$primary};

  text-decoration: var(--#{$prefix}btn-link-decoration);
  border-radius: initial;
  &:hover {
    text-decoration: var(--#{$prefix}btn-link-hover-decoration);
    u {
      text-decoration: none;
    }
  }
}


// Icon button

.btn-icon {
  --#{$prefix}btn-size: #{$btn-icon-size};
  --#{$prefix}btn-font-size: #{$btn-icon-font-size};

  position: relative;
  flex-shrink: 0;
  width: var(--#{$prefix}btn-size);
  height: var(--#{$prefix}btn-size);
  padding: 0;

  // Hover state
  &:hover {
    .btn-icon-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  // Sizing
  &.btn-lg {
    --#{$prefix}btn-size: #{$btn-icon-size-lg};
    --#{$prefix}btn-font-size: #{$btn-icon-font-size-lg};
  }
  &.btn-sm {
    --#{$prefix}btn-size: #{$btn-icon-size-sm};
    --#{$prefix}btn-font-size: #{$btn-icon-font-size-sm};
  }
}


// Dark mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .btn-secondary:not([data-bs-theme="light"]) {
      --#{$prefix}btn-color: #{$gray-200};
      --#{$prefix}btn-bg: #{$gray-700};
      --#{$prefix}btn-border-color: #{$gray-700};
      --#{$prefix}btn-hover-color: #{$white};
      --#{$prefix}btn-hover-bg: #{$gray-600};
      --#{$prefix}btn-hover-border-color: #{$gray-600};
      --#{$prefix}btn-active-color: #{$gray-200};
      --#{$prefix}btn-active-bg: #{$gray-600};
      --#{$prefix}btn-active-border-color: #{$gray-600};
      --#{$prefix}btn-disabled-color: #{$gray-200};
      --#{$prefix}btn-disabled-bg: #{$gray-700};
      --#{$prefix}btn-disabled-border-color: #{$gray-700};
    }

    .btn-dark:not([data-bs-theme="light"]) {
      @extend .btn-light;
    }

    .btn-link:not([data-bs-theme="light"]) {
      --#{$prefix}btn-disabled-color: #{$btn-link-disabled-color-dark};
    }

    .btn-outline-secondary:not([data-bs-theme="light"]) {
      --#{$prefix}btn-color: #{$gray-200};
      --#{$prefix}btn-bg: transparent;
      --#{$prefix}btn-border-color: #{$gray-700};
      --#{$prefix}btn-hover-color: #{$gray-200};
      --#{$prefix}btn-hover-bg: transparent;
      --#{$prefix}btn-hover-border-color: #{$gray-400};
      --#{$prefix}btn-active-color: #{$white};
      --#{$prefix}btn-active-bg: transparent;
      --#{$prefix}btn-active-border-color: #{$white};
      --#{$prefix}btn-disabled-color: #{$gray-200};
      --#{$prefix}btn-disabled-border-color: #{$gray-700};
    }

    .btn-outline-dark:not([data-bs-theme="light"]) {
      --#{$prefix}btn-color: #{$gray-100};
      --#{$prefix}btn-border-color: #{$gray-100};
      --#{$prefix}btn-hover-color: #{$gray-900};
      --#{$prefix}btn-hover-bg: #{$white};
      --#{$prefix}btn-hover-border-color: #{$white};
      --#{$prefix}btn-active-color: #{$gray-900};
      --#{$prefix}btn-active-bg: #{$white};
      --#{$prefix}btn-active-border-color: #{$white};
      --#{$prefix}btn-disabled-color: #{$gray-100};
      --#{$prefix}btn-disabled-border-color: #{$gray-100};
    }
  }
}
