// Dark color mode variables
//
// Custom variables for the `[data-bs-theme="dark"]` theme.

// Global colors

$primary-text-emphasis-dark:        darken($primary, 5%) !default;
$secondary-text-emphasis-dark:      $gray-200 !default;
$success-text-emphasis-dark:        darken($success, 5%) !default;
$info-text-emphasis-dark:           darken($info, 5%) !default;
$warning-text-emphasis-dark:        darken($warning, 5%) !default;
$danger-text-emphasis-dark:         darken($danger, 5%) !default;
$light-text-emphasis-dark:          $gray-200 !default;
$dark-text-emphasis-dark:           $white !default;

$primary-bg-subtle-dark:            shade-color($primary, 85%) !default;
$secondary-bg-subtle-dark:          $gray-800 !default;
$success-bg-subtle-dark:            shade-color($success, 85%) !default;
$info-bg-subtle-dark:               shade-color($info, 85%) !default;
$warning-bg-subtle-dark:            shade-color($warning, 85%) !default;
$danger-bg-subtle-dark:             shade-color($danger, 85%) !default;
$light-bg-subtle-dark:              $gray-900 !default;
$dark-bg-subtle-dark:               shade-color($dark, 85%) !default;

$primary-border-subtle-dark:        shade-color($primary, 70%) !default;
$secondary-border-subtle-dark:      $gray-700 !default;
$success-border-subtle-dark:        shade-color($success, 70%) !default;
$info-border-subtle-dark:           shade-color($info, 70%) !default;
$warning-border-subtle-dark:        shade-color($warning, 70%) !default;
$danger-border-subtle-dark:         shade-color($danger, 70%) !default;
$light-border-subtle-dark:          $gray-700 !default;
$dark-border-subtle-dark:           $gray-800 !default;

$body-color-dark:                   $gray-400 !default;
$body-bg-dark:                      $gray-900 !default;
$body-secondary-color-dark:         $gray-400 !default;
$body-secondary-bg-dark:            $gray-700 !default;
$body-tertiary-color-dark:          $gray-500 !default;
$body-tertiary-bg-dark:             $gray-800 !default;
$body-emphasis-color-dark:          $gray-100 !default;
$user-selection-color-dark:         rgba($white, .12) !default;
$border-color-dark:                 $gray-700 !default;
$headings-color-dark:               $white !default;
$link-color-dark:                   $gray-200 !default;

$component-color-dark:              $gray-200 !default;
$component-hover-color-dark:        $white !default;
$component-hover-bg-dark:           $gray-700 !default;
$component-active-color-dark:       $white !default;
$component-active-bg-dark:          $gray-700 !default;
$component-disabled-color-dark:     $gray-500 !default;


// Focus styles

$focus-ring-opacity-dark:           .12 !default;
$focus-ring-color-dark:             rgba($white, $focus-ring-opacity) !default;


// Tables

$table-striped-bg-factor-dark:      .05 !default;
$table-striped-bg-dark:             rgba($white, $table-striped-bg-factor-dark) !default;

$table-active-bg-factor-dark:       .1 !default;
$table-active-bg-dark:              rgba($white, $table-active-bg-factor-dark) !default;

$table-hover-bg-factor-dark:        .075 !default;
$table-hover-bg-dark:               rgba($white, $table-hover-bg-factor-dark) !default;


// Buttons

$btn-color-dark:                    $gray-200 !default;
$btn-hover-color-dark:              $white !default;
$btn-link-disabled-color-dark:      $gray-400 !default;


// Forms

$input-placeholder-color-dark:                $gray-600 !default;
$input-color-dark:                            $white !default;
$input-bg-dark:                               transparent !default;
$input-border-color-dark:                     $gray-700 !default;
$input-focus-bg-dark:                         transparent !default;
$input-focus-border-color-dark:               $white !default;

$form-check-input-bg-dark:                    $input-bg-dark !default;
$form-check-input-border-dark:                var(--#{$prefix}border-width) solid $input-border-color-dark !default;
$form-check-input-checked-color-dark:         $gray-800 !default;
$form-check-input-checked-bg-color-dark:      $white !default;
$form-check-input-checked-border-color-dark:  $white !default;
$form-check-input-checked-bg-image-dark:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
$form-check-radio-checked-bg-image-dark:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color-dark}'/></svg>") !default;

$form-check-input-indeterminate-color-dark:   $form-check-input-checked-color-dark !default;
$form-check-input-indeterminate-bg-color-dark: $form-check-input-checked-bg-color-dark !default;
$form-check-input-indeterminate-border-color-dark: $form-check-input-checked-border-color-dark !default;
$form-check-input-indeterminate-bg-image-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-switch-bg-color-dark:                    $gray-700 !default;

$form-valid-color-dark:                        $success !default;
$form-valid-border-color-dark:                 $success !default;
$form-invalid-color-dark:                      $danger !default;
$form-invalid-border-color-dark:               $danger !default;


// Input group

$input-group-addon-border-color-dark:   $input-border-color-dark !default;


// Navs

$nav-tabs-link-color-dark:              $gray-400 !default;
$nav-tabs-link-hover-color-dark:        $white !default;
$nav-tabs-link-active-color-dark:       $white !default;
$nav-tabs-link-bg-dark:                 transparent !default;
$nav-tabs-link-hover-bg-dark:           transparent !default;
$nav-tabs-link-active-bg-dark:          $gray-700 !default;
$nav-tabs-link-disabled-bg-dark:        rgba($white, .04) !default;
$nav-tabs-link-disabled-color-dark:     rgba($white, .75) !default;


// Accordion

$accordion-icon-color-dark:             $white !default;
$accordion-icon-active-color-dark:      $white !default;
$accordion-button-icon-dark:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color-dark}'><path d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/></svg>") !default;
$accordion-button-active-icon-dark:     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-active-color-dark}'><path d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/></svg>") !default;
$accordion-button-icon-alt-dark:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color-dark}'><path d='M12 4.125c.483 0 .875.392.875.875v14c0 .483-.392.875-.875.875s-.875-.392-.875-.875V5c0-.483.392-.875.875-.875z'/><path d='M4.125 12c0-.483.392-.875.875-.875h14c.483 0 .875.392.875.875s-.392.875-.875.875H5c-.483 0-.875-.392-.875-.875z'/></svg>") !default;
$accordion-button-active-icon-alt-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-active-color-dark}'><path d='M4.125 12c0-.483.392-.875.875-.875h14c.483 0 .875.392.875.875s-.392.875-.875.875H5c-.483 0-.875-.392-.875-.875z'/></svg>") !default;


// Popovers

$popover-border-color-dark:             $gray-700 !default;


// Breadcrumbs

$breadcrumb-divider-color-dark:         $component-color-dark !default;
$breadcrumb-divider-dark:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$breadcrumb-divider-color-dark}'><path d='M8.381 5.381c.342-.342.896-.342 1.237 0l6 6c.342.342.342.896 0 1.237l-6 6c-.342.342-.896.342-1.237 0s-.342-.896 0-1.237L13.763 12 8.381 6.619c-.342-.342-.342-.896 0-1.237z'/></svg>") !default;
$breadcrumb-divider-flipped-dark:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$breadcrumb-divider-color-dark}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;


// Carousel

$carousel-nav-btn-bg-dark:              rgba($white, .05) !default;
$carousel-nav-btn-color-dark:           $white !default;

$carousel-bullet-bg-dark:               $gray-700 !default;
$carousel-bullet-active-bg-dark:        $white !default;

$carousel-scrollbar-drag-bg-dark:       $white !default;


// Custom scrollbar

$scrollbar-track-bg-dark:               transparent !default;
$scrollbar-bg-dark:                     $gray-600 !default;


// Steps

$steps-number-color-dark:               $white !default;
$steps-number-inner-bg-dark:            $gray-800 !default;
$steps-connect-color-dark:              $gray-700 !default;

$steps-active-number-bg-dark:           rgba($primary, .1) !default;
$steps-active-number-inner-bg-dark:     $primary !default;
$steps-active-number-color-dark:        $white !default;


// List group

$list-group-action-color-dark:          $component-color-dark !default;
$list-group-action-hover-color-dark:    $list-group-hover-color !default;
$list-group-action-hover-bg-dark:       $gray-800 !default;
$list-group-action-active-color-dark:   $list-group-hover-color !default;
$list-group-action-active-bg-dark:      $gray-800 !default;


// Icon-box

$icon-box-color-dark:                   $white !default;
$icon-box-bg-dark:                      $gray-800 !default;
$icon-box-border-color-dark:            $gray-800 !default;


// Breadcrumbs

$breadcrumb-divider-color-dark:         $component-color-dark !default;


// Pagination

$pagination-color-dark:                 $gray-500 !default;
$pagination-bg-dark:                    transparent !default;
$pagination-border-color-dark:          transparent !default;

$pagination-hover-color-dark:           $white !default;
$pagination-hover-bg-dark:              transparent !default;
$pagination-hover-border-color-dark:    $gray-500 !default;

$pagination-active-color-dark:          $white !default;
$pagination-active-bg-dark:             transparent !default;
$pagination-active-border-color-dark:   $white !default;
$pagination-active-box-shadow-dark:     none !default;

$pagination-focus-color-dark:           $white !default;
