// Typography related styles
// stylelint-disable

// Headings

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {

  // Heading link
  > a {
    color: inherit;
    text-decoration: none;
    text-underline-offset: $spacer * .25;

    &:hover {
      text-decoration: underline;
    }
  }
}

h5,
.h5,
h6,
.h6 {
  --#{$prefix}-heading-font-family: inter, sans-serif;
  --#{$prefix}-heading-font-weight: #{$font-weight-semibold};
  font-family: var(--#{$prefix}-heading-font-family) !important;
  font-weight: var(--#{$prefix}-heading-font-weight) !important;
}

h1,
.h1 {
  line-height: $h1-line-height;
}

h2,
.h2 {
  line-height: $h2-line-height;
}

h3,
.h3 {
  line-height: $h3-line-height;
}

h4,
.h4 {
  line-height: $h4-line-height;
}

h5,
.h5 {
  line-height: $h4-line-height;
}

h6,
.h6 {
  line-height: $h4-line-height;
}


// Blockquote

.blockquote {
  font-weight: $blockquote-font-weight;
  color: $blockquote-color;
}

.blockquote-footer {
  margin-top: -$blockquote-margin-y * .5;
}


// Inline list

.list-inline {
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacer * .625 $list-inline-padding;
  margin-bottom: $spacer * .75;
}
// stylelint-enable
