// Icon box

.icon-box {
  --#{$prefix}icon-box-size: #{$icon-box-size};
  --#{$prefix}icon-box-font-size: #{$icon-box-font-size};
  --#{$prefix}icon-box-border-radius: #{$icon-box-border-radius};
  --#{$prefix}icon-box-color: #{$icon-box-color};
  --#{$prefix}icon-box-bg: #{$icon-box-bg};
  --#{$prefix}icon-box-border-color: #{$icon-box-border-color};

  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--#{$prefix}icon-box-size);
  height: var(--#{$prefix}icon-box-size);
  font-size: var(--#{$prefix}icon-box-font-size);
  color: var(--#{$prefix}icon-box-color);
  text-decoration: none;
  background-color: var(--#{$prefix}icon-box-bg);
  border: $border-width solid var(--#{$prefix}icon-box-border-color);
  transition: background-color .25s;
  @include border-radius(var(--#{$prefix}icon-box-border-radius));

  // Sizing
  &.icon-box-sm {
    --#{$prefix}icon-box-size: #{$icon-box-sm-size};
    --#{$prefix}icon-box-font-size: #{$icon-box-sm-font-size};
  }
}


// Dark color mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .icon-box {
      --#{$prefix}icon-box-color: #{$icon-box-color-dark};
      --#{$prefix}icon-box-bg: #{$icon-box-bg-dark};
      --#{$prefix}icon-box-border-color: #{$icon-box-border-color-dark};
    }
  }
}
