// Extend default Bootstrap's .nav, .nav-item, .nav-link classes

.nav {
  --#{$prefix}nav-link-line-height: #{$nav-link-line-height};
  --#{$prefix}nav-link-active-color: #{$nav-link-active-color};

  flex-direction: row;
  gap: 0;
}

.nav-link {
  display: flex;
  align-items: center;

  &.show {
    color: var(--#{$prefix}nav-link-hover-color);
  }

  &.active {
    color: var(--#{$prefix}nav-link-active-color);
  }

  &.disabled,
  &:disabled {
    color: var(--#{$prefix}nav-link-disabled-color);
  }
}


// Nav-column padding

.nav.flex-column {
  --#{$prefix}nav-link-padding-x: initial;
  --#{$prefix}nav-link-padding-y: initial;
  gap: $spacer * .75;
}


// Nav tabs

.nav-tabs {
  --#{$prefix}nav-tabs-link-bg: #{$nav-tabs-link-bg};
  --#{$prefix}nav-tabs-link-hover-bg: #{$nav-tabs-link-hover-bg};
  --#{$prefix}nav-tabs-link-disabled-bg: #{$nav-tabs-link-disabled-bg};
  --#{$prefix}nav-tabs-link-color: #{$nav-tabs-link-color};
  --#{$prefix}nav-tabs-link-hover-color: #{$nav-tabs-link-hover-color};
  --#{$prefix}nav-tabs-link-disabled-color: #{$nav-tabs-link-disabled-color};

  .nav-link {
    color: var(--#{$prefix}nav-tabs-link-color);
    background-color: var(--#{$prefix}nav-tabs-link-bg);
    @include border-radius(var(--#{$prefix}nav-tabs-border-radius));

    &:hover:not(.active),
    &.show:not(.active) {
      color: var(--#{$prefix}nav-tabs-link-hover-color);
      background-color: var(--#{$prefix}nav-tabs-link-hover-bg);
    }
    &.disabled,
    &[disabled] {
      color: var(--#{$prefix}nav-tabs-link-disabled-color);
      background-color: var(--#{$prefix}nav-tabs-link-disabled-bg);
    }
  }

  .dropdown-menu {
    @include border-top-radius(var(--#{$prefix}dropdown-border-radius));
  }
}


// Dark color mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .nav-tabs:not([data-bs-theme="light"]) {
      --#{$prefix}nav-tabs-link-bg: #{$nav-tabs-link-bg-dark};
      --#{$prefix}nav-tabs-link-hover-bg: #{$nav-tabs-link-hover-bg-dark};
      --#{$prefix}nav-tabs-link-active-bg: #{$nav-tabs-link-active-bg-dark};
      --#{$prefix}nav-tabs-link-disabled-bg: #{$nav-tabs-link-disabled-bg-dark};
      --#{$prefix}nav-tabs-link-color: #{$nav-tabs-link-color-dark};
      --#{$prefix}nav-tabs-link-hover-color: #{$nav-tabs-link-hover-color-dark};
      --#{$prefix}nav-tabs-link-active-color: #{$nav-tabs-link-active-color-dark};
      --#{$prefix}nav-tabs-link-disabled-color: #{$nav-tabs-link-disabled-color-dark};
    }
  }
}
