// Dropdown
/* stylelint-disable declaration-no-important, selector-no-qualifying-type */

// Caret
.dropdown-toggle {
  display: flex;
  align-items: center;

  &::before,
  &::after {
    margin-top: -.0625rem;
    font: {
      family: $icons-font-family;
      size: 1.375em;
      weight: normal;
    }
    line-height: 1;
    vertical-align: middle !important;
    border: none !important;
  }
  &::after {
    margin: {
      right: -.25rem;
      left: .25rem;
    }
    content: "\f119";
  }
}

.dropup .dropdown-toggle::after {
  content: "\f11a";
}
.dropstart .dropdown-toggle {
  &::after {
    display: none;
  }
  &::before {
    margin: {
      right: .25rem;
      left: -.25rem;
    }
    content: "\f11a";
  }
}
.dropend .dropdown-toggle::after {
  margin-right: -.3125rem;
  content: "\f11b";
}
.dropdown-toggle-split {
  &::before,
  &::after { margin: 0; }
}

.dropdown .dropdown-toggle {
  &.show::after {
    content: "\f11c";
  }
}
/* stylelint-enable declaration-no-important, selector-no-qualifying-type */


// Dropdown menu

.dropdown-menu {
  --#{$prefix}dropdown-item-border-radius: #{$dropdown-item-border-radius};
  --#{$prefix}dropdown-item-spacer: #{$dropdown-item-spacer};

  transition: border-color .25s ease-in-out;

  &.show {
    animation: dropdown-show .15s ease-in-out;
  }
}

[data-bs-toggle="dropdown-hover"] + .dropdown-menu {
  &::before,
  &::after {
    position: absolute;
    display: block;
    content: "";
  }
}

.dropdown,
.dropup {
  .dropdown-menu {
    margin: var(--#{$prefix}dropdown-spacer) 0 !important; // stylelint-disable-line declaration-no-important
  }

  [data-bs-toggle="dropdown-hover"] + .dropdown-menu {
    &::before,
    &::after {
      left: 0;
      width: 100%;
      height: calc(var(--#{$prefix}dropdown-spacer) * 2);
    }
    &::before {
      bottom: 100%;
    }
    &::after {
      top: 100%;
    }
  }
}

.dropstart,
.dropend {
  .dropdown-menu {
    margin: 0 var(--#{$prefix}dropdown-spacer) !important; // stylelint-disable-line declaration-no-important
  }

  [data-bs-toggle="dropdown-hover"] + .dropdown-menu {
    &::before,
    &::after {
      top: 0;
      width: calc(var(--#{$prefix}dropdown-spacer) * 2);
      height: 100%;
    }
    &::before {
      left: calc(var(--#{$prefix}dropdown-spacer) * -2);
    }
    &::after {
      left: 100%;
    }
  }
}


// Dropdown item

.dropdown-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: var(--#{$prefix}dropdown-item-spacer);
  @include border-radius(var(--#{$prefix}dropdown-item-border-radius));
  cursor: pointer;
  transition: $dropdown-link-transition;

  &.show,
  &.active {
    color: var(--#{$prefix}dropdown-link-hover-color);
    background-color: var(--#{$prefix}dropdown-link-hover-bg);
  }

  &:focus-visible {
    outline: none;
    box-shadow: $focus-ring-box-shadow;
  }

  &.dropdown-toggle::after {
    margin-left: auto;
  }

  .item-acive-indicator {
    display: none;
  }

  &.active .item-acive-indicator {
    display: flex;
    padding-left: .5rem;
  }
}


// Fade in animation

@keyframes dropdown-show  {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
