// Root CSS variables

:root,
[data-bs-theme="light"] {

  // Theme colors
  --#{$prefix}component-color: #{$component-color};
  --#{$prefix}component-hover-color: #{$component-hover-color};
  --#{$prefix}component-hover-bg: #{$component-hover-bg};
  --#{$prefix}component-active-color: #{$component-active-color};
  --#{$prefix}component-active-bg: #{$component-active-bg};
  --#{$prefix}component-disabled-color: #{$component-disabled-color};

  // User selection color
  --#{$prefix}user-selection-color: #{$user-selection-color};

  // Add xs border-radius
  --#{$prefix}border-radius-xs: #{$border-radius-xs};
}


// Dark theme overrides

@if $enable-dark-mode {
  @include color-mode(dark, true) {

    // Theme colors
    --#{$prefix}component-color: #{$component-color-dark};
    --#{$prefix}component-hover-color: #{$component-hover-color-dark};
    --#{$prefix}component-hover-bg: #{$component-hover-bg-dark};
    --#{$prefix}component-active-color: #{$component-active-color-dark};
    --#{$prefix}component-active-bg: #{$component-active-bg-dark};
    --#{$prefix}component-disabled-color: #{$component-disabled-color-dark};

    // User selection color
    --#{$prefix}user-selection-color-dark: #{$user-selection-color-dark};

    // Focus styles
    --#{$prefix}focus-ring-opacity: #{$focus-ring-opacity-dark};
    --#{$prefix}focus-ring-color: #{$focus-ring-color-dark};
  }
}
