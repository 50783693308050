// Utilities
// Extend / update default Bootstrap's utility classes

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

/* stylelint-disable stylistic/value-list-max-empty-lines, stylistic/function-max-empty-lines, declaration-no-important */
$utilities: map-merge(
  $utilities,
  (
    // Remove utilities
    "link-offset": null,
    "link-underline": null,
    "link-underline-opacity": null,

    // Width
    "width": (
      property: width,
      responsive: true,
      class: w,
      values: (
        25: 25%,
        50: 50%,
        100: 100%,
        auto: auto
      )
    ),

    // z-index
    "z-index": (
      property: z-index,
      class: z,
      values: map-merge(
        $zindex-levels,
        (
          sticky: $zindex-sticky,
          fixed: $zindex-fixed
        )
      )
    ),
  )
);


// Fix Safari quirk of rounded corners

[class^="rounded"].overflow-hidden,
[class*=" rounded"].overflow-hidden {
  transform: translateZ(0);
}


// Dark mode

@if $enable-dark-mode {
  @include color-mode(dark) {

    // Display utilities
    .d-none-dark {
      display: none !important;
    }
    .d-block-dark {
      display: block !important;
    }
    .d-inline-dark {
      display: inline !important;
    }
    .d-inline-block-dark {
      display: inline-block !important;
    }
    .d-inline-flex-dark {
      display: inline-flex !important;
    }
    .d-flex-dark {
      display: flex !important;
    }

    // Shadows
    .shadow:not([data-bs-theme="light"]),
    .shadow-sm:not([data-bs-theme="light"]),
    .shadow-lg:not([data-bs-theme="light"]) {
      box-shadow: none !important;
    }
  }
}


// Rotate-onscroll animation
.rotate-animation {
  transition: transform .5s ease-out !important;
}


// Hover scale effect
.hover-scale {
  --#{$prefix}hover-scale-value: #{$hover-scale-value};
  --#{$prefix}hover-scale-transition-timing: #{$hover-scale-transition-timing};

  display: block;

  img,
  svg {
    will-change: transform;
    transition: transform var(--#{$prefix}hover-scale-transition-timing) ease-in-out;
  }
  &:hover {
    img,
    svg {
      transform: translateZ(0) scale(var(--#{$prefix}hover-scale-value));
    }
  }
}
/* stylelint-enable stylistic/value-list-max-empty-lines, stylistic/function-max-empty-lines, declaration-no-important */
