// Navbar

.navbar {
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;

  &.navbar-stuck {
    position: sticky;
    &.position-absolute {
      position: fixed !important; // stylelint-disable-line declaration-no-important
    }
    &:not(.bg-dark) {
      background-color: $dark;
    }
  }


  // Container
  .container {
    flex-wrap: nowrap;
  }


  // Navbar nav
  .navbar-nav {
    .nav .nav-link {
      &:hover,
      &.active {
        text-decoration: underline;
      }
    }
  }


  // Mega dropdown
  .dropdown-mega {
    .dropdown-menu {
      --#{$prefix}dropdown-padding-y: 0;
      --#{$prefix}dropdown-padding-x: 0;
    }
  }


  // Icon btns
  .btn-icon.btn-lg {
    --#{$prefix}btn-size: #{$btn-icon-size};
  }


  // Secondary icon buttons
  .btn-icon.btn-outline-light {
    --#{$prefix}btn-color: #{$gray-200};
    --#{$prefix}btn-hover-color: #{$gray-200};
    --#{$prefix}btn-active-color: #{$gray-200};
    --#{$prefix}btn-border-color: transparent;
    --#{$prefix}btn-hover-bg: transparent;
    --#{$prefix}btn-active-bg: transparent;
  }


  // Navbar before collapse
  @include media-breakpoint-up(lg) {
    --#{$prefix}navbar-color: #{$navbar-dark-color};
    --#{$prefix}navbar-hover-color: #{$navbar-dark-hover-color};
    --#{$prefix}navbar-disabled-color: #{$navbar-dark-disabled-color};
    --#{$prefix}navbar-active-color: #{$navbar-dark-active-color};
    --#{$prefix}navbar-brand-color: #{$navbar-dark-brand-color};
    --#{$prefix}navbar-brand-hover-color: #{$navbar-dark-brand-hover-color};

    // Dropdown
    .dropdown {
      &::before {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        display: none;
        height: $navbar-padding-y * 1.25;
        content: "";
      }

      &:hover::before {
        display: block;
      }
      .dropdown-menu {
        --#{$prefix}dropdown-spacer: 0;
        --#{$prefix}dropdown-min-width: 25.625rem;
        top: 62px !important; // stylelint-disable-line declaration-no-important
      }
    }

    // Mega dropdown
    .dropdown-mega {
      position: static;

      .dropdown-menu {
        width: 100%;
      }
    }

    // Icon btns
    .btn-icon.btn-lg {
      --#{$prefix}btn-size: #{$btn-icon-size-lg};
    }
  }


  // Navbar after collapse
  @include media-breakpoint-down(lg) {
    --#{$prefix}navbar-padding-y: #{$spacer * .875};

    // Dropdown
    .dropdown-menu {
      --#{$prefix}dropdown-padding-y: 0;
      --#{$prefix}dropdown-padding-x: 0;
      --#{$prefix}dropdown-border-width: initial;
      --#{$prefix}dropdown-bg: initial;
      --#{$prefix}dropdown-box-shadow: initial;
    }
  }
}


// Navbar toggler

.navbar-toggler {
  position: relative;
  width: $navbar-toggler-bar-width + $navbar-toggler-padding-x * 2;
  height: $navbar-toggler-bar-height * 3 + $navbar-toggler-bar-spacing * 2 + $navbar-toggler-padding-y * 2;
}

.navbar-toggler-icon {
  top: 50%;
  display: block;
  margin-top: $navbar-toggler-bar-height * -.5;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-duration: .075s;

  &,
  &::before,
  &::after {
    position: absolute;
    width: $navbar-toggler-bar-width;
    height: $navbar-toggler-bar-height;
    background-color: $gray-200;
    transition-property: transform;
  }
  &::before,
  &::after {
    display: block;
    content: "";
  }
  &::before {
    top: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
    transition: top .075s .12s ease, opacity .075s ease, width .15s ease-in-out;
  }
  &::after {
    right: 0;
    bottom: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
    transition: bottom .075s .12s ease, transform .075s cubic-bezier(.55, .055, .675, .19), width .15s ease-in-out;
  }
}

.navbar-toggler:not(.active):not([aria-expanded="true"]):hover .navbar-toggler-icon {
  &::before,
  &::after {
    width: $navbar-toggler-bar-width * .75;
  }
}

.navbar-toggler.active,
[aria-expanded="true"] {
  .navbar-toggler-icon {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: rotate(45deg);
    &::before,
    &::after {
      width: $navbar-toggler-bar-width;
    }
    &::before {
      top: 0;
      opacity: 0;
      transition: top .075s ease, opacity .075s .12s ease;
    }
    &::after {
      bottom: 0;
      transition: bottom .075s ease, transform .075s .12s cubic-bezier(.215, .61, .355, 1);
      transform: rotate(-90deg);
    }
  }
}


// Sticky navbar

.navbar-stuck {
  animation: navbar-show .25s;
}

@keyframes navbar-show  {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}


// Dark mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .navbar:not([data-bs-theme="light"]) {
      --#{$prefix}navbar-color: #{$navbar-dark-color};
      --#{$prefix}navbar-hover-color: #{$navbar-dark-hover-color};
      --#{$prefix}navbar-disabled-color: #{$navbar-dark-disabled-color};
      --#{$prefix}navbar-active-color: #{$navbar-dark-active-color};
      --#{$prefix}navbar-brand-color: #{$navbar-dark-brand-color};
      --#{$prefix}navbar-brand-hover-color: #{$navbar-dark-brand-hover-color};
    }
  }
}
